const menus = {
    day: {
        name: {
            es: 'Menú Mediodía',
            cat: ''
        },
        description: {
            es: 'Nuestros menús del mediodía',
            cat: ''
        },
        time: {
            es: '12:30 - 16:00',
            cat: ''
        },
        price: '15,90€',
        button: {
            es: 'Ver Carta',
            cat: ''
        },
        pdf: './pdfs/CartaDia2024_compressed.pdf'
    },
    night: {
        name: {
            es: 'Menú Noche',
            cat: ''
        },
        description: {
            es: 'Nuestros menús nocturnos',
            cat: ''
        },
        time: {
            es: '20:00 - 23:00',
            cat: ''
        },
        price: '21,90€',
        price2: '- viernes y  festivos + 1€ -',
        button: {
            es: 'Ver Carta',
            cat: ''
        },
        pdf: './pdfs/CartaNoche2024_compressed.pdf'
    },
    festive: {
        name: {
            es: 'Menú Festivos',
            cat: ''
        },
        description: {
            es: 'Nuestro menú nocturno pero con un precio adicional de 1€, viernes cuenta como festivo',
            cat: ''
        }
    },
    pedidos: {
        name: {
            es: 'Carta Pedidos',
            cat: ''
        },
        description: {
            es: 'Nuestros platos disponibles para poder ser llevados a tu casa o al sitio que prefieras',
            cat: ''
        },
        button: {
            es: 'Ver Carta',
            cat: ''
        },
        pdf: './pdfs/CartaLlevar2024_compressed.pdf'
    }
}
export default menus
