const examples = [
    {
        name: 'Uramaki Asia',
        photo: '_uramaki',
        description: {
            es: 'Rollo de sushi con salmón cocido, aguacate, philadelphia y salmón picante.',
            cat: '',
            en: '',
            it: 'Involtino di sushi con salmone cotto, avocado, philadelphia e salmone piccante.'
        }
    },
    {
        name: 'Golden Lobster Roll',
        photo: '_golden',
        description: {
            es: 'Uramaki Especial con langosta a temperatura ambiente, aguacate, trufa y hojas de oro comestibles.',
            cat: '',
            en: '',
            it: 'Uramaki Speciale con astice in tempura, avocado, tartufo e foglie d’oro commestibile.'
        }
    },
    {
        name: 'Poke Bowls Vegeta',
        photo: '_poke',
        description: {
            es: 'Edamame, Aguacate, Zanahorias, Mango y Pepinos.',
            cat: '',
            en: '',
            it: 'Edamame, Avocado, Carote, Mango e Cetrioli.'
        }
    },
    {
        name: 'Samba',
        photo: '_samba',
        description: {
            es: 'Gunkan especial coronado con salmón, lima y alcohol flambeado.',
            cat: '',
            en: '',
            it: 'Gunkan speciale ricoperto di salmone, lime e alcool flambé.'
        }
    },

]

export default examples