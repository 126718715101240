import React from 'react'

const Preload = () => {
  return (
    <div className='preload'>
        <div className='p1'/>
        <div className='p2'/>
        <div className='p3'/>
        <div className='p4'/>
        <div className='p5'/>
        <div className='p6'/>
        <div className='p7'/>
        <div className='p8'/>
        <div className='p9'/>
        <div className='p10'/>
        <div className='p11'/>
        <div className='p12'/>
        <div className='p13'/>
        <div className='p14'/>
        <div className='p15'/>
        <div className='p16'/>
        <div className='p17'/>
        <div className='p18'/>
        <div className='p20'/>
        <div className='p21'/>
        <div className='p22'/>
    </div>
  )
}

export default Preload