const cocina = [
    {
        name: {
            es: 'Cocina Japonesa',
            cat: '',
            en: '',
            it: 'Cucina Giapponese'
        },
        description: {
            es: 'Prueba nuestros platos Japoneses que incluyen Tempura, Onigiri, Ramen y mucho más',
            cat: '',
            en: '',
            it: 'Prova i nostri piatti Giapponesi tra cui i Tempura, gli Onigiri, il Ramen e tanto altro.'
        }
    },
    {
        name: {
            es: 'Cocina China',
            cat: '',
            en: '',
            it: 'Cucina Cinese'
        },
        description: {
            es: 'Sumergete en la cultura culinaria china probando Gua Bao, Fideos Chinos, Giaozi y mucho más',
            cat: '',
            en: '',
            it: 'Gua Bao, spaghetti cinesi, giaozi e tantissimo altro. Immergiti nella cultura culinaria Cinese.'
        }
    },
    {
        name: {
            es: 'Sushi',
            cat: '',
            en: '',
            it: 'Sushi'
        },
        description: {
            es: 'Uramaki, Nigiri, Hosomaki también conocidos como con pescado crudo. Muchas variedades y tipos',
            cat: '',
            en: '',
            it: 'Uramaki, Nigiri, Hosomaki sia cotti che con pesce crudo. Tantissime varietà da assaggiare.'
        }
    },
    {
        name: {
            es: 'Domicilio',
            cat: '',
            en: '',
            it: 'Domicilio'
        },
        description: {
            es: 'Si no puedes o no quieres salir, no te preocupes, ¡nosotros vamos a tu casa! No renuncies a la cocina asiática',
            cat: '',
            en: '',
            it: 'Se non puoi o vuoi uscire, veniamo noi da te! Non rinunciare alla cucina asiatica. Scegli Asia Restaurant'
        }
    },
]

export default cocina