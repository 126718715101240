const cartas = {
  day: {
    es: 'Menú Dia',
    cat: '',
    pdf: './pdfs/CartaDia2024_compressed.pdf'
  },
  night: {
    es: 'Menú Noche',
    cat: '',
    pdf: './pdfs/CartaNoche2024_compressed.pdf'
  },
  festive: {
    es: 'Menú Festivos',
    cat: '',
    pdf: './pdfs/CartaNoche2024_compressed.pdf'
  },
  dulces: {
    es: 'Carta Dulces',
    cat: '',
    // pdf: './pdfs/CartaDulces.pdf'
    pdf: ''
  },
  vinos: {
    es: 'Carta Vinos',
    cat: '',
    pdf: './pdfs/CartaVinos2024v2_compressed.pdf'
  },
  pedidos: {
    es: 'Carta Pedidos',
    cat: '',
    pdf: './pdfs/CartaLlevar2024_compressed.pdf'
  },

}

export default cartas
