const restaurants = {
    corregio: {
        name: 'Asia Correggio',
        country: 'italy',
        street: 'Viale della Repubblica, 33',
        phone: '+39 052269447',
        maps: 'https://maps.google.com/maps?q=ASIA%20Correggio%20-%20Oriental%20Taste,%20Viale%20Repubblica,%20Correggio,%20Province%20of%20Reggio%20Emilia,%20Italy&t=&z=15&ie=UTF8&iwloc=&output=embed',
        whatsapp: 'https://wa.me/+393688049548',
        image: 'asiaCorreggio',
        description: {
            es:'Precioso y delicioso restaurante asiático situado en Italia para que puedas saborear nuestros mejores platos asiáticos. Contamos con una amplia variedad de los mejores onigiris, sashimi, tartare, chirashi, y mucho más.',
            cat:''
        }
    },
    reggioEmilia: {
        name: 'Asia Reggio Emilia',
        country: 'italy',
        street: 'Via Augusto Piccard, 18',
        phone: '+39 0522304295',
        maps: 'https://maps.google.com/maps?q=ASIA%20Restaurant,%20Via%20Augusto%20Piccard,%20Reggio%20Emilia,%20Province%20of%20Reggio%20Emilia,%20Italy&t=&z=15&ie=UTF8&iwloc=&output=embed',
        whatsapp: 'https://wa.me/+393337037297',
        image: 'asiaReggioEmilia',
        description: {
            es:'Bellísimo restaurante en la ciudad de Reggio Emilia, altísimo detalle y un ambiente el cual fácilmente te hará sentir como si estuvieses en china. Te asombrarás con nuestra amplia variedad de platos, cada uno de ellos únicos y exquisitos.',
            cat:''
        }
    },
    ravenna: {
        name: 'Asia Ravenna',
        country: 'italy',
        street: 'Via Faentina, 176',
        phone: '+39 05441580128',
        maps: 'https://maps.google.com/maps?q=Asia%20Restaurant,%20Via%20Faentina,%20Fornace%20Zarattini,%20Province%20of%20Ravenna,%20Italy&t=&z=15&ie=UTF8&iwloc=&output=embed',
        whatsapp: 'https://wa.me/+393311170575',
        image: 'asiaRavenna',
        description: {
            es:'Original y apetitoso restaurante con los platos Orientales más exquisitos de la zona. Una vez que pises este restaurante no querrás volver a pisar ningún otro. Disponemos de una carta con más de 200 platos a degustar.',
            cat:''
        }
    },
    orientalTaste: {
        name: 'Asia Oriental Taste',
        country: 'spain',
        street: 'Vilafranca del Penedès, Carrer de la font, 4',
        phone: '+34 656181427',
        maps: 'https://maps.google.com/maps?q=Carrer%20de%20la%20Font,%204,%2008720%20Vilafranca%20del%20Pened%C3%A8s,%20Barcelona,%20Espa%C3%B1a&t=&z=13&ie=UTF8&iwloc=&output=embed',
        whatsapp: 'https://wa.me/34656181427',
        image: 'asiaOrientalTaste',
        description: {
            es:'Precioso y delicioso restaurante asiático construido recientemente en España para que también puedas disfrutar de los platos culinarios más sabrosos y exquisitos del continente asiático. ¡Nuestra carta de más de 200 platos seguramente te sorprenderá!',
            cat:''
        }
    },
}

export default restaurants