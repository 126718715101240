const meals = [
    {
        name: {
            es: 'Tartare',
            cat: ''
        },
        meals: [
            { name: 'Tartar', photo: 'meal21' },
        ]
    },
    {
        name: {
            es: 'Gua Bao',
            cat: ''
        },
        meals: [
            { name: 'Gua Bao', photo: 'meal22' },
            { name: 'Gua Bao Pork', photo: 'meal23' },
        ]
    },
    {
        name: {
            es: 'Uramaki',
            cat: ''
        },
        meals: [
            { name: 'Rainbow Roll', photo: 'meal12' },
            { name: 'Mexican Roll', photo: 'meal13' },
            { name: 'Spicy Tuna', photo: 'meal14' },
            { name: 'Tiger Roll', photo: 'meal15' },
            { name: 'Future Roll', photo: 'meal16' },
            { name: 'Rollo Vegetal', photo: 'meal17' },
            { name: 'Black Roll', photo: 'meal20' },
        ]
    },
    {
        name: {
            es: 'Especial',
            cat: ''
        },
        meals: [
            { name: 'Golden Roll', photo: 'meal10' },
            { name: 'Miami Roll', photo: 'meal8' },
            { name: 'Black Sweet', photo: 'meal9' },
        ]
    },
    {
        name: {
            es: 'Hosomaki',
            cat: ''
        },
        meals: [
            { name: 'Atsui Maki', photo: 'meal1' },
            { name: 'Niki Sake', photo: 'meal4' },
        ]
    },
    {
        name: {
            es: 'Crispy',
            cat: ''
        },
        meals: [
            { name: 'Crispy Sake', photo: 'meal2' },
        ]
    },
    {
        name: {
            es: 'Nigiri',
            cat: ''
        },
        meals: [
            { name: 'Nigiri Salmón', photo: 'meal25' },
        ]
    },
    {
        name: {
            es: 'Mariscos',
            cat: ''
        },
        meals: [
            { name: 'Gamba limón', photo: 'meal11' },
            { name: 'Red Passion', photo: 'meal6' },
        ]
    },
    {
        name: {
            es: 'Ramen',
            cat: ''
        },
        meals: [
            { name: 'Ramen', photo: 'meal27' },
        ]
    },
    {
        name: {
            es: 'Pollo',
            cat: ''
        },
        meals: [
            { name: 'Pollo', photo: 'meal28' },
        ]
    },
    {
        name: {
            es: 'Pato',
            cat: ''
        },
        meals: [
            { name: 'Pato', photo: 'meal5' },
        ]
    },
    {
        name: {
            es: 'Ternera',
            cat: ''
        },
        meals: [
            { name: 'Ternera', photo: 'meal29' },
        ]
    },
    {
        name: {
            es: 'Arroz',
            cat: ''
        },
        meals: [
            { name: 'Arroz', photo: 'meal26' },
        ]
    },
    {
        name: {
            es: 'Vegetal',
            cat: ''
        },
        meals: [
            { name: 'Vegetariano', photo: 'meal7' },
        ]
    },
]


export default meals